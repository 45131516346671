<template>
  <span class="item-container">
    <div
      @click="itemTicked(item)"
      :class="'item' + (item.complete ? ' completed-item' : '')"
    >
      <h1>
        <span style="font-size: 0.7em" v-if="item.quantity > 1"
          >{{ item.quantity }} x </span
        >{{ item.item }}
        <font-awesome-icon
          icon="check-circle"
          style="color: red"
          v-if="ticked"
        />
      </h1>
      <ul class="list-group">
        <OrderModifier
          :modifier="modifier"
          v-bind:key="order.id + '-' + modifier + '-' + idx"
          v-for="modifier in item.modifiers"
        />
      </ul>
    </div>
    <div v-if="$parent.$parent.training">
      <div v-for="video in $parent.$parent.videos.videos" :key="video">
        <div v-if="video.includes(slug(item.item).toLowerCase())">
          <video-player
            class="video-player-box"
            :options="trainingOptions(video)"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @waiting="onPlayerWaiting($event)"
            @playing="onPlayerPlaying($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
            >
          </video-player>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import OrderModifier from "@/components/OrderModifier";
import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";
//import jsonVideoData from "../../public/videos/data.json";
import slugify from "slugify";

export default {
  name: "OrderItem",
  props: ["item", "order", "idx"],
  components: { OrderModifier, videoPlayer },
  data: function () {
    return {
//      jsonVideos: jsonVideoData,
      ticked: false,
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
          },
        ],
      },
    };
  },
  methods: {
    printOrderItem(item) {
      this.$parent.printOrderItem(item, true);
    },
    itemTicked(item) {
      let itemStarted = [];
      if ("events" in item && item.events.length) {
        itemStarted = item.events.filter(
          (event) => event.station == this.$parent.$parent.station
        );
      }
      if (itemStarted.length == 0) {
        this.ticked = !this.ticked;
        this.$parent.$parent.sendItemStarted(this.order.id, item.pk);
      }
    },
    slug(s) {
      return slugify(s);
    },
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPlaying(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    trainingOptions(file) {
      return {
        // videojs options
        muted: true,
        language: "en",
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://" + process.env.VUE_APP_API_HOST + "/videos/" + this.$parent.$parent.company + "-" + file,
          },
        ],
      };
    },
  },
  watch: {
    ticked: function (val, oldVal) {
      if (val) {
        console.log("send to ws", val, oldVal, this.order);
      }
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
};
</script>

<style scoped lang="scss">
.item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.list-group {
  list-style: none;
}
.item {
  transition: border-color 5s ease;
  border: none;
  text-align: left;
  border-radius: 0.3rem;
  background-color: white;
  height: min-content;
  padding: 10px;
  width: 100%;

  &.completed-item {
    //color: red;
    text-decoration: line-through !important;
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    .print-icon {
      font-size: 3rem;
      padding: 10px;
      color: #2a3439;
      cursor: pointer;
    }
  }
}
.video-player {
  width: 15rem;
}

.item:last-child {
  margin-bottom: 0px;
}

.item h1 {
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0px;
  border-radius: 5px;
  font-size: 1.4em;
  font-weight: 800;
  text-decoration: underline;
}
</style>
