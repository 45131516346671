<template>
  <div class="loading-spinner">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150">
      <path
        fill="none"
        stroke="#3BAFDA"
        stroke-width="15"
        stroke-linecap="round"
        stroke-dasharray="300 385"
        stroke-dashoffset="0"
        d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z">
        <animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate>
      </path>
    </svg>
    <h3>{{ spinnerMessage }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    spinnerMessage: {
      type: String,
      required: true,
    },
  },
  name: "LoadingSpinner",
  // Add your component logic here
};
</script>

<style scoped>
.loading-spinner {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background-color: #ffffff70;
  svg {
    width: 15rem;
  }
}
</style>
