<template>
  <h6>+{{ nowFormatted }}</h6>
</template>

<script>
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "OrderTimer",
  props: ["created", "changeStatus"],
  data: function () {
    return {
      created_at_epoch: dayjs(this.created).unix(),
      nowFormatted: null,
      timerRef: null,
      normal: null,
      later: null,
      late: null,
    };
  },

  beforeMount() {
    let waitTimes = JSON.parse(window.localStorage.getItem("waitTimes"));
    if (waitTimes && waitTimes.normal) {
      this.normal = waitTimes.normal;
      this.later = waitTimes.later;
      this.late = waitTimes.late;
    }
  },

  mounted() {
    this.timerRef = setInterval(() => {
      // TODO fix stuff
      var diff = dayjs().unix() - this.created_at_epoch;

      this.now = new Date((dayjs().unix() - this.created_at_epoch) * 1000);

      // console.log(this.now);

      var seconds = this.now.getUTCSeconds() + "";
      if (seconds.length === 1) {
        seconds = "0" + seconds;
      }
      var minutes = this.now.getUTCMinutes() + "";
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      var hours = this.now.getUTCHours() + "";
      if (hours.length === 1) {
        hours = "0" + hours;
      }

      this.nowFormatted = hours + ":" + minutes + ":" + seconds;

      if (this.normal) {
        if (diff > this.normal) {
          this.$emit("changeStatus", { status: "normal" });
        }
      } else if (diff > 240) {
        this.$emit("changeStatus", { status: "normal" });
      }
      if (this.later) {
        if (diff > this.later) {
          this.$emit("changeStatus", { status: "later" });
        }
      } else if (diff > 360) {
        this.$emit("changeStatus", { status: "later" });
      }
      if (this.late) {
        if (diff > this.late) {
          this.$emit("changeStatus", { status: "late" });
        }
      } else if (diff > 480) {
        this.$emit("changeStatus", { status: "late" });
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerRef);
  },
  computed: {
    completed: function () {
      return { "background-color": "#a3ff00" };
    },
  },
};
</script>

<style scoped lang="scss"></style>
