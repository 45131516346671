//import Vue from 'vue'
import { createApp } from "vue";
import App from './App.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faToiletPaper, faScroll, faPrint, faComment, faRunning, faCoffee, faHamburger, faSmileWink, faStar, faHeart, faSmileBeam, faFingerprint,faCheckCircle, faSignOutAlt, faPhone, faEnvelope, faWrench, faTimes, faPersonRunning, faDesktop} from '@fortawesome/free-solid-svg-icons'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//Vue.component('font-awesome-icon', FontAwesomeIcon)


library.add(faToiletPaper, faScroll, faPrint, faRunning, faCheckCircle, faFingerprint, faSmileWink, faSmileBeam, faHeart, faStar, faSignOutAlt, faHamburger, faCoffee, faMinusSquare, faComment, faPhone, faEnvelope, faWrench, faTimes, faPersonRunning, faDesktop)

//Vue.config.productionTip = false

/***new Vue({
  render: h => h(App),
  }).$mount('#app')***/
createApp(App).component("font-awesome-icon", FontAwesomeIcon).mount("#app");