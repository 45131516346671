<template>
  <li
    :class="
      completed ? 'order-item completed ' + status : 'order-item ' + status
    "
    :style="statusColor"
  >
    <div class="details">
      <div class="item_header">
        <h1 style="position: relative" @click="completeStationItems">
          <span class="order-name">{{ order.data.name || "Unknown" }}</span>
          <!--        <div @click="ticked = ! ticked" style="position: absolute; right: 0px; top: 0px;">-->
          <!--          <font-awesome-icon v-if="ticked" icon="smile-beam" :style="{ 'text-stroke': '5px white', color: (ticked ? '#97b0c2': '#ffffff') }"/>-->
          <!--        </div>-->
          <OrderTimer
            @changeStatus="changeStatus"
            :created="order.data.created_at"
          />
        </h1>
        <div>
          <font-awesome-icon
            v-if="order.data.delivery_option == 'online'"
            icon="phone"
            @click.stop.prevent="showPhone = !showPhone"
            class="icon"
          />
          <p v-if="showPhone">{{ order.data.cell }}</p>
          <font-awesome-icon
            v-if="order.data.delivery_option == 'online'"
            icon="envelope"
            @click.stop.prevent="showEmail = !showEmail"
            class="icon"
          />
          <p v-if="showEmail">{{ order.data.email }}</p>
          <font-awesome-icon
            v-if="$parent.printerSelected"
            class="print-icon icon"
            @click.stop.prevent="printOrder"
            icon="print"
          />
        </div>
        <div class="icons"></div>
        <h1>
          <span
            v-if="order.data.delivery_option"
            class="badge badge-info text-capitalize mr-1"
            >{{ order.data.delivery_option }}</span
          >
        </h1>
        <div
          class="updates"
          v-if="order.data.updates && order.data.updates.length != 0"
        >
          <details open>
            <summary class="updates_toggle">Updates</summary>
            <ul>
              <hr />
              <li
                class="item"
                v-for="update in order.data.updates"
                v-bind:key="update.hash"
              >
                <h4>{{ local_time(update.time) }}</h4>
                <h4>{{ update.quantity }} X {{ update.name }}</h4>
                <hr />
              </li>
            </ul>
          </details>
        </div>
        <div
          class="notes"
          v-if="order.data.notes && order.data.notes.length != 0"
        >
          <details open>
            <summary class="updates_toggle">Notes</summary>
            <h4>{{ order.data.notes }}</h4>
          </details>
        </div>
        <hr />
      </div>
      <div class="items" v-if="order">
        <OrderItem
          :item="item"
          :order="order"
          :idx="idx"
          v-bind:key="id + '-item-' + idx"
          v-for="(item, idx) in orderedItems"
        />
      </div>

      <div class="other-items-container" v-if="orderedOtherItems.length > 0">
        <h4 @click="showOther = !showOther">
          + {{ orderedOtherItems.length }} other items
        </h4>
        <div class="other-items-inner-container" v-if="showOther">
          <div
            class="'item' + (item.complete ? ' completed-item': '')"
            v-bind:key="id + '-other-item-' + idx"
            v-for="(item, idx) in orderedOtherItems"
          >
            <h1 @click="completeItem(item)">
              <font-awesome-icon
                icon="coffee"
                v-if="order.data.category === 'Coffee'"
              />
              <font-awesome-icon
                icon="hamburger"
                v-if="order.data.category === 'Bagel'"
              />
              {{ item.item }}
              <!--              <font-awesome-icon icon="check-circle" v-if="item.complete || item.complete === 'True'" />-->
            </h1>
            <ul class="list-group">
              <OrderModifier
                :order="order"
                :modifier="modifier"
                :idx="idx"
                :idy="idy"
                :item="item"
                v-bind:key="id + '-' + modifier + '-' + idx + '-' + idy"
                v-for="(modifier, idy) in item.modifiers.filter((i) => {
                  return !i.match(/^Bagel Type_/);
                })"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import OrderTimer from "@/components/OrderTimer";
import OrderItem from "@/components/OrderItem";
import OrderModifier from "@/components/OrderModifier";

var customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(customParseFormat);

export default {
  name: "Order",
  components: { OrderModifier, OrderTimer, OrderItem },
  props: ["order", "id", "pos", "categories", "onComplete", "channel"],
  data: function () {
    return {
      now: null,
      grabbed: false,
      completed: false,
      status: "pending",
      showOther: false,
      ticked: false,
      result: [],
      delay: 200,
      clicks: 0,
      timer: null,
      showPhone: false,
      showEmail: false,
    };
  },
  methods: {
    printOrder() {
      let itemLength = this.orderedItems.length;
      this.orderedItems.forEach((item) => {
        let currQuantity = item.quantity;
        if (itemLength == 1) {
          while (currQuantity > 1) {
            this.printOrderItem(item, false);
            currQuantity -= 1;
          }
          this.printOrderItem(item, true);
        } else {
          while (currQuantity > 1) {
            this.printOrderItem(item, false);
            currQuantity -= 1;
          }
          this.printOrderItem(item, false);
        }
        // this.$parent.printerDone = false;
        // while (this.$parent.printerDone) {
        //   if (this.$parent.printerDone) {
        //     break;
        //   }
        // }
        itemLength -= 1;
      });
    },
    printOrderItem(item, cut) {
      let printer = JSON.parse(localStorage.getItem("printer"));
      console.log("print", item, this.order);
      let printItem = document.getElementById("print-item");

      printItem.height = "232";
      printItem.width = "496";
      let ctx = printItem.getContext("2d");
      ctx.font = "bold 50px roboto";
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, printItem.width, printItem.height);
      ctx.fillStyle = "#000";
      ctx.fillText(this.order.data.name, 20, 62);

      ctx.font = '40px ffs';
      if (this.order.data.delivery_option == "online") {
        ctx.fillText(
          "\uF1EB",
          ctx.measureText(this.order.data.name).width + 60,
          62
        );
      } else if (this.order.data.delivery_option == "takeaway") {
        ctx.fillText(
          "\uF70C",
          ctx.measureText(this.order.data.name).width + 60,
          62
        );
      }

      // ctx.beginPath(); // Start a new path
      // ctx.moveTo(20, 64); // Move the pen to (30, 50)
      // ctx.lineTo(ctx.measureText(this.order.data.name).width+10, 64); // Draw a line to (150, 100)
      // ctx.lineWidth = 2;
      // ctx.strokeStyle = "#000";
      // ctx.stroke(); // Render the path

      ctx.font = "40px roboto";
      ctx.fillText(item.item, 20, 104);
      let i = 130;
      ctx.font = "bold 20px roboto";

      item.modifiers.forEach((mod) => {
        ctx.fillText(mod.split("_")[1], 20, i);
        i += 21;
      });

      let dataURL = printItem.toDataURL();
      window.Android.printItem(
        dataURL,
        printer["ip"],
        printer["model"],
        printer["label"],
        cut
      );
    },

    changeStatus(event) {
      this.status = event.status;
    },
    clickItem(event, item) {
      item["stationTicked"] = item["stationTicked"]
        ? !item["stationTicked"]
        : true;
    },
    completeItem(item) {
      this.channel.push("order:update", {
        id: this.order.id,
        pk: item.pk,
      });
    },
    completeStationItems() {
      this.orderedItems.map((item) => {
        if (!item.complete) {
          this.channel.push("order:update", {
            id: this.order.id,
            pk: item.pk,
          });
        }
      });
    },
    local_time(utc_time) {
      const local_time = new Date(utc_time);
      return local_time.toLocaleTimeString();
    },
  },
  computed: {
    orderedItems: function () {
      return this.order.data.items.filter((item) => {
        return this.categories.includes(item.category);
      });
    },
    orderedOtherItems: function () {
      return this.order.data.items.filter((item) => {
        return !this.categories.includes(item.category);
      });
    },
    statusColor: function () {
      if (this.status === "pending") {
        return { "background-color": "#FAED1F", left: this.pos * 270 + "px" };
      }
      if (this.status === "normal") {
        return { "background-color": "#ff8400", left: this.pos * 270 + "px" };
      }
      if (this.status === "late") {
        return { "background-color": "red", left: this.pos * 270 + "px" };
      }
      if (this.status === "later") {
        return { "background-color": "#ff8400", left: this.pos * 270 + "px" };
      }
      return { "background-color": "#a3ff00", left: this.pos * 270 + "px" };
    },
  },
};
</script>

<style scoped lang="scss">
.other-items-container {
  display: none;
  margin: 10px;
  border: 1px solid #ccc;
  background-color: greenyellow;
  border-radius: 5px;
  padding: 10px;
}

.updates_toggle {
  font-size: x-large;
  margin: 0.5em;
}

.updates ul {
  padding-left: 0px;
}

.updates .item {
  list-style-type: none;
}

li.order-item {
  padding: 10px;
  white-space: normal;
  vertical-align: top;
  transition: background-color 5s ease;
  &.complete {
    opacity: 0;
    transition: opacity 4s ease;
  }

  .icon {
    cursor: pointer;
    margin: 0 0.5rem;
    font-size: 3rem;
    color: #596269;
  }

  // &.pending .item {
  //   border: 2px dashed #000;
  // }

  .details {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .item_header {
    width: 100%;
  }

  .items {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    gap: 10px;
    grid-auto-columns: 220px;
    overflow: scroll;
    font-size: 1em;
    max-height: calc(100vh - 240px);
    //scrolling-padding: 10px;
    margin-left: 30px;
    padding-bottom: 10px;
  }

  .items::-webkit-scrollbar {
    width: 30px;
  }

  .items::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  .items::-webkit-scrollbar-thumb {
    background-color: #fff; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    background-clip: padding-box;
    border: 8px solid transparent;
  }
  .items::-webkit-scrollbar-corner {
    background: transparent;
  }

  // .items:child {
  //   padding-right: 10px;
  // }

  .command {
    width: 100%;
    margin: 0px auto;

    .btn {
      width: calc(100% - 20px);
      padding: 2em;
      margin-top: 10px;
    }
    .btn.grabbed {
      background-color: orangered;
    }

    .btn span {
      background-color: red;
      display: inline-block;
    }
    .makeitso {
      opacity: 0;
    }
    .makeitso:hover {
      opacity: 1;
      transition: opacity 2s;
    }

    .btn.grabbed .makeitso {
      position: absolute;
      left: 20px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      top: 0px;
      width: 150px;
      height: 110.5px;
    }
  }

  &.completed {
    position: relative;
    margin-top: -1000px;
    transition: margin-top ease-in-out 0.5s;
  }
}

ul.list-group {
  list-style: none;
}

.player {
  display: none;
}

.badge {
  background-color: #596269 !important;
}
</style>
