<template>
  <div id="messageModal" v-if="open" class="reviewOrder modal" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Messages</h4>
          <b @click="this.$parent.closeMessageModal">X</b>
        </div>
        <h5 class="modal-title">Send</h5>
        <div class="modal-body form-group" style="display: flex; justify-content: space-around">
          <ul>
            <b>Site:</b>
            <li @click="msgSetSite(site)" class="site" :id="site" :key="'company-' + company + '-site-' + idx" v-for="(site, idx) in sites">
              {{ site }}
            </li>
          </ul>
          <ul>
            <b>Station:</b>
            <div v-for="(station, idx) in stations" :key="'company-' + company + '-site-' + station.name + '-station-' + idx">
              <li @click="msgSetStation(station.name)" class="station" :id="station.name.replace(/\s/g, '')" v-if="station.name !== currStation">
                {{ station.name }}
              </li>
            </div>
          </ul>
          <div v-if="messageRecieved" class="break"></div>
          <ul v-if="messageRecieved" style="width: 300px;">
            <b>Message:</b>
            <li>{{ messageRecieved.msg }} from {{ messageRecieved.sender }}</li>
          </ul>
        </div>
        <div>
          <form v-on:submit.prevent="sendMsg">
            <fieldset :disabled="this.msgSite && this.msgStation ? null : 'disabled'">
              <input type="text" v-model="message" placeholder="Message..." />
              <input type="submit" value="Send msg" />
              <b> -> </b>
              {{ msgSite }}
              {{ msgStation }}
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MessageModal",
  props: ["open", "company", "sites", "stations", "channel", "site", "messageRecieved"],
  data() {
    return {
      messageModal: false,
      msgSite: "",
      msgStation: "",
      message: "",
      currStation: "",
    };
  },
  //   watch:{
  //     messageRecieved: function() {
  //       console.log(this.messageRecieved)
  //     }
  //   },
  mounted() {
    this.currStation = window.localStorage.getItem("station");
  },
  methods: {
    sendMsg() {
      this.message === ""
        ? null
        : this.channel.push("message:send", {
            site: this.msgSite,
            station: this.msgStation,
            msg: this.message,
            company: this.company,
          });
    },
    msgSetSite(site) {
      console.log(window.localStorage.getItem("station"));
      if (this.msgSite === site) {
        this.msgSite = "";
        document.querySelector(`#${site}`).style.border = "1px solid rgba(0, 0, 0, 0.2)";
      } else {
        this.msgSite = site;
        document.querySelectorAll(".site").forEach((siteElement) => (siteElement.style.border = "1px solid rgba(0, 0, 0, 0.2)"));
        document.querySelector(`#${site}`).style.border = "2px solid black";
      }
    },
    msgSetStation(station) {
      if (this.msgStation === station) {
        this.msgStation = "";
        document.querySelector(`#${station.replace(/\s/g, "")}`).style.border = "1px solid rgba(0, 0, 0, 0.2)";
      } else {
        this.msgStation = station;
        document.querySelectorAll(".station").forEach((stationElement) => (stationElement.style.border = "1px solid rgba(0, 0, 0, 0.2)"));
        document.querySelector(`#${station.replace(/\s/g, "")}`).style.border = "2px solid black";
      }
    },
  },
};
</script>
<style scoped lang="scss">
#messageModal {
  background-color: rgba(0, 0, 0, 0.5) !important;

  .break {
    border-left: 3px solid black;
    height: 30rem;
  }

  ul {
    height: auto;

    .selected {
      border: 2px solid black;
    }

    li {
      background-color: #97b0c2;
      padding: 4px;
      margin: 8px;
      border-radius: 0.2rem;
      border: 1px solid rgba(0, 0, 0, 0.2);
      min-width: 120px;
    }
  }

  form {
    padding: 10px;
  }
}

.reviewOrder ul {
  list-style: none;
  white-space: nowrap;
  margin: 0px;
  height: 600px;
  width: 400px;
  padding: 0px;
  overflow-y: scroll;
  li:first-child {
    padding-bottom: 20px;
  }
}

.modal {
  background-color: #eeeeee;

  .error {
    text-align: left;
    font-size: 1.2em;
    margin-left: 20px;
    text-transform: capitalize;
    color: red;
  }
}
</style>
